import request from "@/util/request";
import requestFile from "@/util/reques-file";
import { GroupModel, MaterialModel } from "./apiModel";

/**
 * 获取分组列表
 * @returns
 */
export function getGroupList(): any {
  return request({
    url: "/getgroup",
    method: "get",
  });
}

/**
 * 编辑分组
 * @param query
 * @returns
 */
export function updateGroup(query: GroupModel): any {
  return request({
    url: "/updategroup",
    method: "post",
    data: { json: JSON.stringify(query) },
  });
}

/**
 * 删除分组
 * @param query
 * @returns
 */
export function deleteGroup(query: string): any {
  return request({
    url: `/deletegroup?id=${query}`,
    method: "get",
  });
}

/**
 * 新增分组
 * @param query
 * @returns
 */
export function addGroup(query: GroupModel): any {
  return request({
    url: "/savegroup",
    method: "post",
    data: { json: JSON.stringify(query) },
  });
}

/**
 * 根据分组ID获取素材列表
 * @param id
 * @returns
 */
export function getModelByGroupId(id: string, search: string): any {
  return request({
    url: `/getmouldlistbygroupid?id=${id}&search=${search}`,
    method: "get",
  });
}

/**
 * 搜索
 * @param search
 * @returns
 */
export function getAllMould(search: string): any {
  return request({
    url: `/getmouldlist?search=${search}`,
    method: "get",
  });
}

/**
 * 根据ID获取素材
 * @param id
 * @returns
 */
export function getModelById(id: string): any {
  return request({
    url: `/getmouldbyid?id=${id}`,
    method: "get",
  });
}

/**
 * 上传图片
 * @param data
 * @returns
 */
export function uploadPic(data: any): any {
  return requestFile({
    url: "/uploadImage",
    method: "post",
    data: data,
  });
}

/**
 * 新增模板
 * @param data
 * @returns
 */
export function addNewModel(data: MaterialModel): any {
  return request({
    url: "/savemould",
    method: "post",
    data: { json: JSON.stringify(data) },
  });
}

/**
 * 编辑模板
 * @param data
 * @returns
 */
export function uploadModel(data: MaterialModel): any {
  return request({
    url: "/updatemould",
    method: "post",
    data: { json: JSON.stringify(data) },
  });
}

/**
 * 删除图片
 * @param id
 * @returns
 */
export function deleteModule(idList: Array<string>): any {
  return request({
    url: `/batchdeletemould`,
    method: "post",
    data: { json: JSON.stringify(idList) },
  });
}
