
import { defineComponent, ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { getModelById } from "@/api/group";
import { getOrderById, getContentById } from "@/api/order";
import { Order } from "@/api/apiModel";
import Watermark from "@/util/waterMark";
import { downPdf } from "@/util/html2pdf";

export default defineComponent({
  name: "ExportPDF",
  components: {},
  setup() {
    const route = useRoute();
    const routeQuery = route.query as any; // {id:订单ID或者模板ID,type:workbench/mix,isExport:export/preview}
    let btnLoading = ref(true);

    const mould = ref("");
    // 获取模板
    const getMould = async (id: string) => {
      const res = await getModelById(id);
      if (res.code == 200) {
        mould.value = res.data.mould;
      }
    };
    // 获取订单
    let orderUser = ref<Order>({
      id: "",
      jsonstring: "",
      name: "",
      phone: "",
      age: "",
      height: "",
      weight: "",
      faceImgName: [],
      faceImgType: "",
      contentId: "",
      remark: "",
      orderType: "",
      orderStatus: "",
      createUserId: "",
      createtime: "",
      updateUserId: "",
    });

    let cssStr = ref("");
    const getOrder = async (id: string) => {
      const order = await getOrderById(id); // 获取基本信息
      orderUser.value = order.data;
      const res = await getContentById(routeQuery.id);
      if (res.code == 200) {
        mould.value = res.data.content;
        cssStr.value = res.data.bgColor;
      }
    };

    let contentClass = computed(() => {
      if (cssStr.value == "" || cssStr.value == undefined) {
        return "";
      }
      return JSON.parse(cssStr.value);
    });

    let isShowBtn = ref(true);
    const exportPdf = () => {
      btnLoading.value = true;
      downPdf(orderUser.value.name);
      setTimeout(() => {
        btnLoading.value = false;
      }, 15 * 1000);
    };
    onMounted(async () => {
      Watermark.set("");
      btnLoading.value = true;
      if (routeQuery.type == "workbench") {
        await getOrder(routeQuery.id);
      } else {
        await getMould(routeQuery.id);
      }
      btnLoading.value = false;
      if (routeQuery.isExport == "export") {
        setTimeout(() => {
          exportPdf();
        }, 200);
      }
    });
    return { mould, exportPdf, isShowBtn, btnLoading, contentClass };
  },
});
