import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "@/util/oauth";
import { ElMessage } from "element-plus";

const service = axios.create({
  baseURL:
    process.env.NODE_ENV == "development" || process.env.NODE_ENV == "stage"
      ? "http://47.99.120.188:6004/api/face" // 测试
      : "https://facemaster.meb.fit/api/face", // 正式
  timeout: 1000 * 120, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.headers != undefined) {
      config.headers.token = getToken(); // 在这里添加认证需要的Token
      //   config.data = qs.stringify(config.data);
      config.headers["Content-Type"] =
        "multipart/form-data; boundary=----WebKitFormBoundary9ajyIO6VT1JlFJvy";
    }
    return config;
  },
  (error) => {
    // 请求报错执行
    console.log(error);
    Promise.reject(error);
  }
);

// resposne拦截器
service.interceptors.response.use(
  (response) => response.data,
  (error) => {
    console.log("err" + error); // for debug
    ElMessage.error("上传失败" + error);
    return Promise.reject(error);
  }
);

export default service;
