import html2canvas from "html2canvas";
import JsPDF from "jspdf";
// https://blog.csdn.net/weixin_51499232/article/details/114967481

export function downPdf(title: string): void {
  const div = document.getElementById("pdfContent") || document.body;
  div.style.overflow = "hidden";
  setTimeout(() => {
    const element = div;
    const width = element.offsetWidth;
    const height = element.offsetHeight;
    const scale = 2;
    const opts = {
      dpi: window.devicePixelRatio * 2,
      scale: scale,
      width: width,
      height: height,
      useCORS: true,
    };

    html2canvas(element, opts).then((canvas) => {
      const contentWidth = canvas.width;
      const contentHeight = canvas.height;
      const pdfWidth = ((contentWidth + 10) / 2) * 0.75;
      const pdfHeight = ((contentHeight + 200) / 2) * 0.75;
      const imgWidth = pdfWidth;
      const imgHeight = (contentHeight / 2) * 0.75;
      const pageData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new JsPDF("p", "pt", [pdfWidth, pdfHeight]);
      pdf.addImage(pageData, "jpeg", 0, 0, imgWidth, imgHeight);
      pdf.save(`${title}.pdf`);
      element.style.backgroundColor = "transparent";
      div.style.overflow = "visible";
    });
  }, 3000);
}
